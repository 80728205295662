import { Box, Button, Grid, Typography } from "@mui/material";
import { FC, useContext } from "react";
import { neutral, warm } from "style/color";
import { ReactComponent as Localization } from "./../../../assets/Icons/localization.svg";
import { ReactComponent as EyeOpen } from "./../../../assets/Icons/eye-open.svg";

import { moneyFormat } from "utils/money-format";
import {
  CashInfo,
  GRANT_PURPOSE_ENUM,
  GRANT_REQUEST_TRANSACTION_TYPE_ENUM,
  GRANT_REQUEST_TYPE_ENUM,
  GrantRequestInput,
  InvestmentInfo,
  OnTimeSchedule,
  RecurrentSchedule,
} from "types/grant-request";
import { shortFormat } from "utils/date-format";
import {
  compileHtmlToPDF,
  generateAcknowledgementLetterHtml,
} from "utils/create-pdf";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import { Charity, einFormat } from "types/charity";
import { INVESTMENT_REQUEST_INTEREST_ENUM } from "types/investment-request";

interface GrantSummaryProps {
  grantRequest: GrantRequestInput;
  charity: Charity;
}

export const GrantSummary: FC<GrantSummaryProps> = ({
  grantRequest,
  charity,
}) => {
  const { selectedAccount, userData } = useContext<AuthData>(AuthContext);

  const openPdf = () => {
    const amount =
      grantRequest.transactionType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH
        ? (grantRequest.information as CashInfo).requestedAmount
        : (grantRequest.information as InvestmentInfo).requestedAmount;

    compileHtmlToPDF(
      generateAcknowledgementLetterHtml({
        acknowledgement: grantRequest.acknowledgement,
        fund: selectedAccount!,
        user: userData?.user!,
        charity: charity,
        amount: amount,
        purpose: grantRequest.grantPurpose,
        personToInform: grantRequest.toInform,
      })
    );
  };
  console.log(grantRequest);
  return (
    <>
      <Box
        sx={{
          paddingTop: 2,
          paddingBottom: 2.5,
          marginY: 2.5,
          borderBlockEnd: "2px",
          borderBlockColor: warm[500],
          borderBlockEndStyle: "solid",
        }}
      >
        <Typography variant="smallCopy" color={neutral[500]}>
          GRANT TO
        </Typography>
        <br></br>
        <Typography variant="subtitle3" color={neutral[900]}>
          {charity.name}
        </Typography>
        <br />
        <Typography variant="bodyCopyRegular" color={neutral[600]}>
          {einFormat(charity.ein)}
        </Typography>
        <Grid container spacing={1}>
          <Grid item>
            <Localization />
          </Grid>
          <Grid item>
            <Typography variant="smallCopyBold" color={neutral[600]}>
              {charity.street} {charity.street2},<br />
              {charity.city}, {charity.state} {charity.zip}, {charity.country}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          paddingTop: 2,
          paddingBottom: 2.5,
          marginY: 2.5,
          borderBlockEnd: "2px",
          borderBlockColor: warm[500],
          borderBlockEndStyle: "solid",
        }}
      >
        <Typography variant="smallCopy" color={neutral[500]}>
          TOTAL GRANT
        </Typography>
        <br></br>
        <Typography variant="subtitle3" color={neutral[900]}>
          {grantRequest.transactionType ===
            GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH && (
            <>
              $
              {moneyFormat(
                +(grantRequest.information as CashInfo).requestedAmount
              )}
            </>
          )}
          {grantRequest.transactionType ===
            GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT && (
            <>
              $
              {moneyFormat(
                +(grantRequest.information as InvestmentInfo).requestedAmount
              )}{" "}
              {(grantRequest.information as InvestmentInfo).interestedIn ===
                INVESTMENT_REQUEST_INTEREST_ENUM.UNITS && "(estimated)"}
            </>
          )}
        </Typography>
        <br />
        {grantRequest.transactionType ===
          GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT && (
          <>
            {(grantRequest.information as InvestmentInfo).interestedIn ===
              INVESTMENT_REQUEST_INTEREST_ENUM.UNITS && (
              <>
                <Typography variant="smallCopyBold" color={neutral[600]}>
                  Paid in cash from the money of selling the following
                  investment:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item>
                    <Grid>
                      <Typography variant="smallCopy" color={neutral[600]}>
                        $
                        {moneyFormat(
                          +(grantRequest.information as InvestmentInfo).units,
                          4,
                          2
                        )}{" "}
                        units of{" "}
                        {(grantRequest.information as InvestmentInfo).ticker}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {(grantRequest.information as InvestmentInfo).interestedIn ===
              INVESTMENT_REQUEST_INTEREST_ENUM.AMOUNT && (
              <>
                <Typography variant="smallCopyBold" color={neutral[600]}>
                  Paid in cash from the money of selling enough units of{" "}
                  {(grantRequest.information as InvestmentInfo).ticker}
                </Typography>
              </>
            )}
          </>
        )}
      </Box>
      <Box
        sx={{
          paddingTop: 2,
          paddingBottom: 2.5,
          marginY: 2.5,
          borderBlockEnd: "2px",
          borderBlockColor: warm[500],
          borderBlockEndStyle: "solid",
        }}
      >
        <Typography variant="smallCopy" color={neutral[500]}>
          FREQUENCY
        </Typography>
        <br></br>
        <Typography variant="subtitle3" color={neutral[900]}>
          {grantRequest.type === GRANT_REQUEST_TYPE_ENUM.ONETIME && (
            <>
              One time,{" "}
              {shortFormat(
                (grantRequest.scheduleInformation as OnTimeSchedule)
                  .executionDate
              )}
            </>
          )}
          {grantRequest.type === GRANT_REQUEST_TYPE_ENUM.RECURRENT && (
            <>
              {(
                grantRequest.scheduleInformation as RecurrentSchedule
              ).frequency.toLowerCase()}
              , from{" "}
              {shortFormat(
                (grantRequest.scheduleInformation as RecurrentSchedule)
                  .startingOn
              )}{" "}
              to{" "}
              {shortFormat(
                (grantRequest.scheduleInformation as RecurrentSchedule).endingOn
              )}
            </>
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          paddingTop: 2,
          paddingBottom: 2.5,
          marginY: 2.5,
          borderBlockEnd: "2px",
          borderBlockColor: warm[500],
          borderBlockEndStyle: "solid",
        }}
      >
        <Typography variant="smallCopy" color={neutral[500]}>
          GRANT PURPOSE
        </Typography>
        <br></br>
        <Typography variant="subtitle3" color={neutral[900]}>
          {grantRequest.grantPurpose.type ===
          GRANT_PURPOSE_ENUM.UNRESTRICTED ? (
            <>Unrestricted</>
          ) : (
            <>Specific : {grantRequest.grantPurpose.note}</>
          )}
        </Typography>
      </Box>
      {grantRequest.acknowledgement.thirdPartiesEmails.length !== 0 && (
        <Box
          sx={{
            paddingTop: 2,
            paddingBottom: 2.5,
            marginY: 2.5,
            borderBlockEnd: "2px",
            borderBlockColor: warm[500],
            borderBlockEndStyle: "solid",
          }}
        >
          <Typography
            className="title"
            variant="smallCopy"
            color={neutral[500]}
          >
            Send to third parties that will be notified{" "}
          </Typography>
          <br></br>
          {grantRequest.acknowledgement.thirdPartiesEmails.map((email) => (
            <>
              <Typography key={email} variant="subtitle3" color={neutral[900]}>
                {email}
              </Typography>
              <br></br>
            </>
          ))}
        </Box>
      )}

      <Box
        sx={{
          paddingTop: 2,
          paddingBottom: 2.5,
          marginY: 2.5,
          borderBlockEnd: "2px",
          borderBlockColor: warm[500],
          borderBlockEndStyle: "solid",
        }}
      >
        <Typography variant="smallCopy" color={neutral[500]}>
          ACKNOWLEDGMENT LETTER
        </Typography>
        <br></br>
        <Button variant="outlined" startIcon={<EyeOpen />} onClick={openPdf}>
          See letter preview
        </Button>
      </Box>
      <Box
        sx={{
          paddingTop: 2,
        }}
      >
        <Typography variant="smallCopy" color={neutral[500]}>
          VISIBILITY
        </Typography>
        <br></br>
        <Typography variant="subtitle3" color={neutral[900]}>
          {grantRequest.acknowledgement.visibility
            ? "I agree to have my fund’s name shared publicly by the charity"
            : "I don't agree to have my fund’s name shared publicly by the charity"}
        </Typography>
      </Box>
    </>
  );
};
