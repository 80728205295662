// @ts-nocheck
import React, { FC } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";

import { GrantVestment } from "../../types/grant-vestment";
import { ReactComponent as NotificationsIcon } from "./../../assets/Icons/notification.svg";
import { ReactComponent as ThumbUpIcon } from "./../../assets/Icons/thumb-up.svg";
import { ReactComponent as ThumbDownIcon } from "./../../assets/Icons/thumb-down.svg";
import { ReactComponent as NotificationsSmallIcon } from "./../../assets/Icons/notification-small.svg";
import { ReactComponent as ThumbUpSmallIcon } from "./../../assets/Icons/thumb-up-small.svg";
import { ReactComponent as ThumbDownSmallIcon } from "./../../assets/Icons/thumb-down-small.svg";
import { useNavigate } from "react-router-dom";
import { getPercentage, moneyFormat } from "../../utils/money-format";
import { numberOfDays, shortFormat } from "../../utils/date-format";
import imagePlaceholader from "../../assets/image-placeholader.png";

interface GrantVestmentsCardProps {
  grantvestment: GrantVestment;
  single: Boolean;
  allContent: boolean;
}

const GrantVestmentsCard: FC<GrantVestmentsCardProps> = ({
  grantvestment,
  single,
  allContent,
}) => {
  const navigate = useNavigate();
  const funded = getPercentage(
    grantvestment?.raisedAmount,
    grantvestment?.goalAmount
  );
  const daysLeft = (numberOfDays(grantvestment?.deadline) < 0) ? 0 : numberOfDays(grantvestment?.deadline);
  const handleClick = () => {
    navigate(`/grantvestments/${grantvestment?.name}`, {
      state: { id: grantvestment?.grantVestmentId },
    });
  };
  const getContent = () => {
    return (
      <>
        {" "}
        <CardContent>
          <Typography
            variant="smallCopy"
            color="neutral.main"
            className="title"
            gutterBottom
          >
            {grantvestment?.category?.categoryName}
          </Typography>
          <Box sx={{ height: single ? "auto" : "180px" }}>
            <Typography
              onClick={(event) => handleClick(event)}
              sx={{
                color: "neutral.main",
                "&:hover": { cursor: "pointer" },
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                whiteSpace: "pre-wrap",
              }}
              variant="h3"
              gutterBottom
            >
              {grantvestment?.name}
            </Typography>
            <Typography
              variant="bodyCopyRegular"
              sx={{
                color: "neutral.lighter",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
                whiteSpace: "pre-wrap",
              }}
              paragraph
            >
              {grantvestment?.description}
            </Typography>
          </Box>
          {allContent ? (
            <>
              <Typography
                variant="smallCopy"
                className="title"
                sx={{ color: "neutral.lighter" }}
                paragraph
              >
                Raised
              </Typography>
              <Typography
                variant="bodyCopyBold"
                sx={{ color: "neutral.main" }}
                gutterBottom
              >
                ${moneyFormat(grantvestment?.raisedAmount)}{" "}
                <Typography variant="smallCopyBold">
                  out of ${moneyFormat(grantvestment?.goalAmount)} goal
                </Typography>
              </Typography>
              <Box sx={{ width: "100%", my: 2 }}>
                <LinearProgress
                  color="primary"
                  variant="determinate"
                  value={parseFloat(funded) > 100 ? 100 : parseInt(funded)}
                />
              </Box>
              <Typography
                variant="smallCopy"
                color={"primary"}
                className="title"
                paragraph
              >
                {funded}% funded
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="smallCopy"
                color={"primary"}
                className="title"
                paragraph
              >
                {funded}% funded
              </Typography>
              <Box sx={{ width: "100%", my: 2 }}>
                <LinearProgress
                  color="primary"
                  variant="determinate"
                  value={parseFloat(funded) > 100 ? 100 : parseInt(funded)}
                />
              </Box>
            </>
          )}

          <Typography
            variant="smallCopy"
            className="title"
            sx={{ color: "neutral.lighter" }}
            paragraph
          >
            {grantvestment?.status === "RAISING" ? (
              <>{daysLeft} days left</>
            ) : (
              <>closed on {shortFormat(grantvestment?.closedAt)} </>
            )}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <Grid container rowSpacing={2} alignItems={"center"}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{ textAlign: { xs: "center", md: "start" } }}
            >
              <Typography
                variant="bodyCopyBold"
                sx={{ color: "neutral.main" }}
                gutterBottom
              >
                {grantvestment?.projectBy}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{ textAlign: { xs: "center", md: "end" } }}
            >
              <IconButton className="grantvenstment">
                {" "}
                {single ? <NotificationsIcon /> : <NotificationsSmallIcon />}
              </IconButton>
              <IconButton className="grantvenstment">
                {single ? <ThumbUpIcon /> : <ThumbUpSmallIcon />}
              </IconButton>
              <IconButton className="grantvenstment" color="secondary">
                {single ? <ThumbDownIcon /> : <ThumbDownSmallIcon />}
              </IconButton>
            </Grid>
          </Grid>
        </CardActions>
      </>
    );
  };
  return single ? (
    <>
      <Card sx={{ display: { xs: "block", sm: "block", md: "flex" } }}>
        <CardMedia
          onClick={(event) => handleClick(event)}
          component="img"
          sx={{
            width: { xs: "100%", md: "33%" },
            height: "auto",
            aspectRatio: "1.25/1",
            "&:hover": { cursor: "pointer" },
            flex: 2,
          }}
          image={(grantvestment.imageUrl) ? grantvestment.imageUrl : imagePlaceholader}
          alt="Live from space album cover"
        />
        <Box sx={{ m: 3, width: "-webkit-fill-available", flex: 3 }}>
          {getContent()}
        </Box>
      </Card>
    </>
  ) : (
    <Card className="list" onClick={(event) => handleClick(event)}>
      <CardMedia
        component="img"
        sx={{
          width: "100%",
          aspectRatio: allContent ? "1.25/1" : "2/1",
          "&:hover": { cursor: "pointer" },
          flex: 2,
        }}
        image={(grantvestment.imageUrl) ? grantvestment.imageUrl : imagePlaceholader}
        alt="Live from space album cover"
      />
      <Box sx={{ m: 3, width: "-webkit-fill-available", flex: 3 }}>
        {getContent()}
      </Box>
    </Card>
  );
};

export default GrantVestmentsCard;
