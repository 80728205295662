import apiHttpClient from "libs/api-http-client";
import { User, UserInput } from "types/user";

export const addUserAPI = (data: UserInput): Promise<unknown> =>
  apiHttpClient.post(`/register/userWithInvite`, data);

export const updateUserAPI = (data: UserInput): Promise<unknown> =>
  apiHttpClient.patch("users/" + data.sub, data);

export const SignupUserAPI = (data: UserInput): Promise<unknown> =>
  apiHttpClient.post(`/register/userWithFund`, data);

export const getUserAPI = (sub: string): Promise<User> => {
  if (sub) {
    return apiHttpClient.get("users/sub", {
      params: {
        sub: sub,
      },
    });
  }
  return {} as any;
};
export const getUserByEmailAPI = (email: string): Promise<User> =>
  apiHttpClient.get(`users/email/${email}`);
