import React, { useContext } from "react";
import { Box, CircularProgress, Container, Grid } from "@mui/material";
import Layout from "components/layout/layout";
import { useParams } from "react-router-dom";

import { ReactComponent as InstantMoney } from "./../../assets/Icons/instant-money.svg";
import { TransactionDetailsCard } from "components/transaction-details/transaction-details";
import { TransactionDetailsItem } from "components/transaction-details/transaction-details-item";
import { shortFormat } from "utils/date-format";
import { BackToPreviousButton } from "components/shared/back-to-previous-button";
import { useGetFundFeeQuery } from "hooks/use-services/fund-fee.service";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
export const MyFeeTransactionDetailsPage = () => {
  const { id } = useParams();
  const { selectedAccount } = useContext<AuthData>(AuthContext);
  const {
    data: feeData,
    isSuccess,
    isFetching,
    isLoading,
  } = useGetFundFeeQuery({
    fundAccountId: selectedAccount?.fundAccountId || 0,
    feeId: id ? +id : 0,
  });

  return (
    <Layout>
      {() => (
        <>
          <Box sx={{ my: 4, width: "100%" }}>
            <React.Fragment>
              <Container>
                {isFetching ||
                  (isLoading && <CircularProgress size={20} color="inherit" />)}
                {isSuccess && feeData && (
                  <Grid sx={{ m: 5 }} container spacing={2}>
                    <Grid item xs={12} sm={9}>
                      <BackToPreviousButton />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <TransactionDetailsCard
                        amount={-feeData.amount}
                        type="NETA FEE"
                        title={"Neta Foundation"}
                        avatar={<InstantMoney />}
                        status={"PROCESSED"}
                      >
                        <TransactionDetailsItem
                          label="Type"
                          value={`Monthly fee Neta`}
                        />
                        <TransactionDetailsItem
                          label="Date"
                          value={shortFormat(feeData.referenceDate)!}
                        />
                        <TransactionDetailsItem
                          label="Fund"
                          value={`${feeData.fundAccount.fundAccountName}`}
                        />
                      </TransactionDetailsCard>
                    </Grid>
                  </Grid>
                )}
              </Container>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
