import React, { useContext, useEffect } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  SvgIcon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  FormHelperText,
  TextField,
} from "@mui/material";

import { ReactComponent as CashIcon } from "./../../assets/Icons/Cash.svg";
import { ReactComponent as ErrorIcon } from "./../../assets/Icons/error.svg";
import { ReactComponent as InvestmentsIcon } from "./../../assets/Icons/fluctuations.svg";
import { ReactComponent as AssetsIcon } from "./../../assets/Icons/assets.svg";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContributionTransaction, {
  CashInformation,
  OtherAssetsInformation,
  PAYMENT_METHOD_ENUM,
  SecuritiesInformation,
  TRANSACTION_TYPE_ENUM,
} from "types/contribution-transaction";
import { moneyFormat } from "utils/money-format";
import { NumericFormatCustom } from "components/shared/currency-input";
import { NetaSetting } from "constants/neta-settings";
import { NetaSettingContext } from "providers/netaSettingsProvider";

const CASH: CashInformation = {
  requestedAmount: "" as any,
  paymentMethod: PAYMENT_METHOD_ENUM.TRANSFER,
};
const SECURITIES: SecuritiesInformation = {
  securityName: "",
  ticker: "",
  quantity: "" as any,
};

interface ContributionTransactionDetailsProps {
  contributionTransaction?: ContributionTransaction;
  handleChange: (key: string, value: any) => void;
}
export const ContributionTransactionDetails: React.FC<
  ContributionTransactionDetailsProps
> = ({ contributionTransaction, handleChange }) => {
  const NETA = useContext<NetaSetting>(NetaSettingContext);

  const [type, setType] = React.useState<TRANSACTION_TYPE_ENUM>(
    TRANSACTION_TYPE_ENUM.CASH
  );
  const [information, setInformation] = React.useState<
    CashInformation | SecuritiesInformation | OtherAssetsInformation
  >(CASH);
  const [error, setError] = React.useState({
    requestedAmount: false,
    securityName: false,
    ticker: false,
    quantity: false,
  });
  const [minAmountError, setMinAmountError] = React.useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (contributionTransaction) {
      setType(contributionTransaction.type);
      setInformation(contributionTransaction.contributionInformation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    handleChange("contributionInformation", information);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [information]);
  const handleType = (
    event: React.MouseEvent<HTMLElement>,
    newType: TRANSACTION_TYPE_ENUM | null
  ) => {
    if (newType !== null) {
      setType(newType);
    }
    handleChange("type", newType);
    setError({
      requestedAmount: false,
      securityName: false,
      ticker: false,
      quantity: false,
    });
    switch (newType) {
      case TRANSACTION_TYPE_ENUM.CASH:
        setInformation(
          contributionTransaction?.type === newType
            ? contributionTransaction.contributionInformation
            : CASH
        );
        break;
      case TRANSACTION_TYPE_ENUM.SECURITIES:
        setInformation(
          contributionTransaction?.type === newType
            ? contributionTransaction.contributionInformation
            : SECURITIES
        );
        break;
      case TRANSACTION_TYPE_ENUM.OTHER:
        setInformation({});
        break;
      default:
        setInformation(CASH);
    }
  };
  const handleInfromation = (key: string, value: string | number) => {
    setError({
      ...error,
      [key]: false,
    });
    if (type === TRANSACTION_TYPE_ENUM.CASH) {
      setInformation({
        ...information,
        paymentMethod: (contributionTransaction?.contributionInformation as CashInformation).paymentMethod,
        [key]: value,
      });
    }
    else {
      setInformation({
        ...information,
        [key]: value,
      });
    }

  };
  const handleError = (key: string, event?: any) => {
    setError({
      ...error,
      [key]: true,
    });
  };
  const handleMinAmount = () => {
    if (
      (information as CashInformation).requestedAmount !== ("" as any) &&
      +(information as CashInformation).requestedAmount <
      NETA.contributionMinimumAmount
    ) {
      setMinAmountError(true);
    } else {
      setMinAmountError(false);
    }
  };

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          tabIndex={-1}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Details"
          id="Details"
        >
          <Typography sx={{ color: `neutral.main` }} variant="subtitle1">
            Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ color: `neutral.dark` }}
            variant="bodyCopyRegular"
            gutterBottom
          >
            Our team will require this information to identify your payment.
          </Typography>
          <Grid sx={{ my: 3 }} container spacing={2}>
            <Grid item xs={12} sm={10}>
              <Typography variant="subtitle3" sx={{ color: `neutral.main` }}>
                Type
              </Typography>
            </Grid>
            <Grid item xs={12} sm={11}>
              <ToggleButtonGroup
                fullWidth
                size="medium"
                value={type}
                onChange={handleType}
                exclusive
                color="primary"
              >
                <ToggleButton
                  fullWidth
                  className="add-money-toggle"
                  value={TRANSACTION_TYPE_ENUM.CASH}
                  aria-label="left aligned"
                >
                  <SvgIcon sx={{ mr: 2 }}>
                    {" "}
                    <CashIcon />{" "}
                  </SvgIcon>{" "}
                  Cash
                </ToggleButton>
                <ToggleButton
                  fullWidth
                  className="add-money-toggle"
                  value={TRANSACTION_TYPE_ENUM.SECURITIES}
                  aria-label="centered"
                >
                  <SvgIcon sx={{ mr: 2 }}>
                    {" "}
                    <InvestmentsIcon />{" "}
                  </SvgIcon>{" "}
                  Securities
                </ToggleButton>
                <ToggleButton
                  fullWidth
                  className="add-money-toggle"
                  value={TRANSACTION_TYPE_ENUM.OTHER}
                  aria-label="right aligned"
                >
                  <SvgIcon sx={{ mr: 2 }}>
                    {" "}
                    <AssetsIcon />{" "}
                  </SvgIcon>{" "}
                  Other assets
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {type === TRANSACTION_TYPE_ENUM.CASH && (
              <Grid item xs={12} sm={11} sx={{ mt: 2 }}>
                <FormControl
                  error={error.requestedAmount || minAmountError}
                  fullWidth
                >
                  <InputLabel shrink={false}>Enter amount </InputLabel>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="subtitle3"
                      sx={{ color: `neutral.main`, px: 2 }}
                    >
                      $
                    </Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      name="requestedAmount"
                      value={(information as CashInformation).requestedAmount}
                      onChange={(event) =>
                        handleInfromation("requestedAmount", event.target.value)
                      }
                      onBlur={handleMinAmount}
                      onMouseMove={handleMinAmount}
                      onInvalid={(event) =>
                        handleError("requestedAmount", event)
                      }
                      id="formatted-numberformat-input"
                      InputProps={{
                        inputComponent: NumericFormatCustom as any,
                      }}
                      required
                      variant="outlined"
                    />
                  </Box>
                  {error.requestedAmount && (
                    <FormHelperText>
                      {" "}
                      <ErrorIcon />
                      Please enter valid value{" "}
                    </FormHelperText>
                  )}
                  {minAmountError && (
                    <FormHelperText>
                      {" "}
                      <ErrorIcon /> Minimum amount ${" "}
                      {moneyFormat(NETA.contributionMinimumAmount)}{" "}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            )}
            {type === TRANSACTION_TYPE_ENUM.SECURITIES && (
              <>
                <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel shrink={false}>
                      Please enter the details below{" "}
                    </InputLabel>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ pt: 0 }}>
                  <FormControl error={error.securityName} fullWidth>
                    <OutlinedInput
                      sx={{ width: "100%" }}
                      name="securityName"
                      placeholder="Security Name"
                      value={
                        (information as SecuritiesInformation).securityName
                      }
                      onChange={(event) =>
                        handleInfromation("securityName", event.target.value)
                      }
                      onInvalid={(event) => handleError("securityName", event)}
                      type="text"
                      required
                    />
                    {error.securityName && (
                      <FormHelperText>
                        <ErrorIcon />
                        Please enter valid value{" "}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ pt: 0 }}>
                  <FormControl error={error.ticker} fullWidth>
                    <OutlinedInput
                      sx={{ width: "100%" }}
                      name="ticker"
                      placeholder="Ticker"
                      value={(information as SecuritiesInformation).ticker}
                      onChange={(event) =>
                        handleInfromation("ticker", event.target.value)
                      }
                      onInvalid={(event) => handleError("ticker")}
                      type="text"
                      required
                    />
                    {error.ticker && (
                      <FormHelperText>
                        <ErrorIcon />
                        Please enter valid value{" "}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} sx={{ pt: 0 }}>
                  <FormControl error={error.quantity} fullWidth>
                    <OutlinedInput
                      sx={{ width: "100%" }}
                      name="quantity"
                      placeholder="Quantity"
                      value={(information as SecuritiesInformation).quantity}
                      onChange={(event) =>
                        handleInfromation("quantity", event.target.value)
                      }
                      onInvalid={(event) => handleError("quantity")}
                      type="number"
                      inputProps={{ min: 0, step: "any" }}
                      required
                    />
                    {error.quantity && (
                      <FormHelperText>
                        <ErrorIcon />
                        Please enter valid value{" "}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
