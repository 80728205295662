import apiHttpClient from "libs/api-http-client";
import { FundUsersRoles } from "types/FundUsersRoles";
import PaginatedEntityList from "types/PaginatedEntityList";
import {
  FundAccountInvitation,
  FundAccountInvitationInput,
  USER_STATUS,
} from "types/fund-account-invitation";

export const inviteUserToFundAPI = (
  data: FundAccountInvitationInput
): Promise<unknown> => apiHttpClient.post(`/fund-account-invitation`, data);
export const updateUserInvitationAPI = (
  id: number,
  data: {
    email: string;
    role: string;
  }
): Promise<unknown> =>
  apiHttpClient.patch(`/fund-account-invitation/${id}`, data);

export interface GetUsersInvitationsQuery {
  offset?: number;
  limit?: number;
  filter?: {
    fundId?: number;
    email?: string;
    status?: USER_STATUS;
  };
}
export interface GetFundRolesQuery {
  offset?: number;
  limit?: number;
  fundId: number;
}
export const getUsersInvitationsAPI = (
  query: GetUsersInvitationsQuery
): Promise<PaginatedEntityList<FundAccountInvitation>> =>
  apiHttpClient.get(`/fund-account-invitation`, {
    params: {
      limit: query.limit,
      offset: query.offset,
      filter: {
        fundId: query.filter?.fundId,
        email: query.filter?.email,
        status: query.filter?.status,
      },
    },
  });
export const GetFundRolesApi = (
  query: GetFundRolesQuery
): Promise<PaginatedEntityList<FundUsersRoles>> =>
  apiHttpClient.get(`/fund/roles`, {
    params: {
      limit: query.limit,
      offset: query.offset,
      fundId: query.fundId,
    },
  });
export const getFundRoleAPI = (id: number): Promise<FundUsersRoles> =>
  apiHttpClient.get(`/fund/roles/${id}`);

export const getUserInviteAPI = (id: number): Promise<FundAccountInvitation> =>
  apiHttpClient.get(`/fund-account-invitation/${id}`);

export const IsUserInviteExpiredAPI = (id: number): Promise<boolean> =>
  apiHttpClient.get(`/fund-account-invitation/${id}/expired`);

export const deleteUserInviteAPI = (id: number): Promise<any> =>
  apiHttpClient.delete(`/fund-account-invitation/${id}`);

export const deleteUserFromFundAPI = (id: number): Promise<any> =>
  apiHttpClient.delete(`/fund-account-member/${id}`);

export const updateUserRoleAPI = (
  id: number,
  data: {
    role: string;
  }
): Promise<unknown> => apiHttpClient.patch(`/fund-account-member/${id}`, data);
export const manageUserInvitationAPI = (data: {
  invitationId: number;
  status: USER_STATUS;
}): Promise<unknown> =>
  apiHttpClient.patch(
    `/fund-account-invitation/${data.invitationId}/manage`,
    data
  );
