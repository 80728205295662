import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  OutlinedInput,
  Snackbar,
  SnackbarCloseReason,
  Typography,
} from "@mui/material";
import { signUp, signIn } from "aws-amplify/auth";

import { User, UserError } from "types/user";
import { ExpandMore } from "@mui/icons-material";
import { useLookup } from "api/lookupService";
import Layout from "components/layout/layout";
import { Address } from "components/user-information/address";
import { ContactInformation } from "components/user-information/contact-information";
import { PersonalInformation } from "components/user-information/personal-information";
import { USER, USER_ERROR } from "constants/user";
import { BackToPreviousButton } from "components/shared/back-to-previous-button";
import { useParams } from "react-router-dom";
import { EmailConfirm } from "./email-confirm";
import { useAddUserToFundMutation } from "hooks/use-services/user.service";
import { errorAlert } from "style/color";
import { useIsUserInviteExpiredQuery } from "hooks/use-services/fund-account-invitations.service";
import mixpanel from "mixpanel-browser";
type SignUpParameters = {
  username: string;
  password: string;
};
export const SignUpByInvite = () => {
  const { id, email, fund, role } = useParams();
  const [target, setTarget] = React.useState<any>(null);
  const [scroll, setScroll] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const handleFutureDateError = (target: any, error: any) => {
    setTarget(error ? target : null);
  };
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordReqError, setPasswordReqError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(false);
  const [acceptAgreement, setAcceptAgreement] = React.useState(false);
  const [acceptPolicy, setAcceptPolicy] = React.useState(false);

  const [user, setUser] = React.useState<User>(USER);
  const [error, setError] = React.useState<UserError>(USER_ERROR);
  const [titles, setTitles] = React.useState([]);
  const setLookupData = (data: any) => {
    setTitles(data.titles);
  };
  useLookup(setLookupData);
  const { data } = useIsUserInviteExpiredQuery(parseInt(id || "0"))
  const { mutate: addUserMutation } = useAddUserToFundMutation()
  const validatePassword = (password: string) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return `Password must be at least ${minLength} characters long.`;
    } else if (!hasUpperCase) {
      return 'Password must contain at least one uppercase letter.';
    } else if (!hasLowerCase) {
      return 'Password must contain at least one lowercase letter.';
    } else if (!hasNumber) {
      return 'Password must contain at least one number.';
    } else if (!hasSpecialChar) {
      return 'Password must contain at least one special character.';
    }

    return '';
  };
  const handleChange = (key: string, value: any) => {
    setScroll(false);
    setError({ ...error, [key]: false });
    setUser({
      ...user,
      [key]: value,
    });
  };
  const handleInvalid = (event: any) => {
    if (!scroll) {
      event.target.scrollIntoView({ behavior: "smooth", block: "center" });
      setScroll(true);
    } else {
      event.preventDefault();
    }
    setError({
      ...error,
      [event.target.name]: true,
    });
  };
  const handleSignUp = async ({ username, password }: SignUpParameters) => {
    try {
      const { userId } = await signUp({
        username,
        password,
      });
      handleChange("sub", userId);
      setOpen(true);
    } catch (error: any) {
      if (error.name === "UsernameExistsException") {
        setOpenError(true);
      }
    }
  };
  const submit = async (event: any) => {
    event.preventDefault();
    if (target) {
      target.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      await handleSignUp({ username: email!, password });
    }
  };
  const addUser = async () => {
    setOpen(false);
    const input = { ...user, email: email!, inviteId: parseInt(id || "0") };
    addUserMutation(input, {
      onSuccess: async () => {
        try {
          mixpanel.track('UserSignedUpWithInvitation', {
            user: `${user.firstName} ${user.lastName}`,
            email: email,
            fundAccount: fund
          });
          await signIn({ username: email!, password });
          window.location.reload();
        } catch (error) {
          console.log("Error signing in:", error);
        }
      },
    });
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };
  return (
    <Layout>
      {() => (
        <>{data && <Alert
          severity="error"
          variant="filled"
          sx={{ width: "100%", backgroundColor: errorAlert[300] }}
        >
          This invitation has been expired. Please <Link target="_blank" href={`mailto:admin@netafoundation.org`} underline="always" color={'inherit'} sx={{
            "&:hover": {
              cursor: "pointer",
            }
          }}> contact us</Link> for further assistance.
        </Alert>}
          <Container>
            <Box sx={{ my: 4, width: "100%" }}>
              <React.Fragment>
                <Container sx={{ my: 8, width: "100%" }}>
                  <Grid sx={{ mb: 5 }} container spacing={2}>
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      autoHideDuration={3000}
                      open={openError}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="error"
                        variant="filled"
                        sx={{ width: "100%", backgroundColor: errorAlert[300] }}
                      >
                        {"There is already an account with this email"}
                      </Alert>
                    </Snackbar>


                    <Grid item xs={12} sm={8}>
                      <BackToPreviousButton></BackToPreviousButton>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Typography variant="h3">
                        Please sign up to become {(role === 'VIEWER') ? "a" : 'an'} {role} to {fund}
                      </Typography>
                      <Typography variant="bodyCopyRegular">
                        Please complete all mandatory fields (*).
                      </Typography>
                    </Grid>
                  </Grid>
                  <EmailConfirm
                    open={open}
                    email={email!}
                    handleClose={addUser}
                  ></EmailConfirm>
                  <Box component="form" autoComplete="off" onSubmit={submit}>
                    <div>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          tabIndex={-1}
                          expandIcon={<ExpandMore />}
                          aria-controls="login"
                          id="login"
                        >
                          <Typography
                            sx={{ color: `neutral.main` }}
                            variant="subtitle1"
                          >
                            Login Information{" "}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid sx={{ mt: 2 }} container spacing={2}>
                            <Grid item xs={12} sm={8}>
                              <FormControl fullWidth>
                                <InputLabel shrink={false}>Email*</InputLabel>
                                <OutlinedInput
                                  name="email"
                                  value={email!}
                                  type="text"
                                  required
                                  disabled
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <FormControl error={passwordError} fullWidth>
                                <InputLabel shrink={false}>
                                  Password*
                                </InputLabel>
                                <OutlinedInput
                                  value={password}
                                  name="password"
                                  type="password"
                                  onChange={(event) => { setPassword(event.target.value); setPasswordError(false); setPasswordReqError(validatePassword(event.target.value)) }}
                                  onInvalid={(event) => setPasswordError(true)}
                                  required
                                />
                                {passwordError && <FormHelperText color={'red'}>Field is required</FormHelperText>}
                                {passwordReqError && (
                                  <FormHelperText error>{passwordReqError}</FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <FormControl
                                error={confirmPasswordError}
                                fullWidth
                              >
                                <InputLabel shrink={false}>
                                  Repeat password*
                                </InputLabel>
                                <OutlinedInput
                                  value={confirmPassword}
                                  name="password"
                                  type="password"
                                  onChange={(event) => {
                                    setConfirmPassword(event.target.value);
                                    setConfirmPasswordError(false);
                                  }}
                                  onInvalid={(event) =>
                                    setConfirmPasswordError(true)
                                  }
                                  required
                                />
                                {confirmPasswordError && (
                                  <FormHelperText color={"red"}>
                                    Field is required
                                  </FormHelperText>
                                )}
                                {confirmPassword &&
                                  confirmPassword !== password && (
                                    <FormHelperText color={"red"}>
                                      Passwords do not match
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>
                          </Grid>

                          <></>
                        </AccordionDetails>
                      </Accordion>
                      <PersonalInformation
                        titles={titles}
                        user={user}
                        handleChange={handleChange}
                        error={error}
                        handleInvalid={handleInvalid}
                        handleFutureDateError={handleFutureDateError}
                      ></PersonalInformation>
                      <Address
                        user={user}
                        handleChange={handleChange}
                        error={error}
                        handleInvalid={handleInvalid}
                      ></Address>
                      <ContactInformation
                        user={user}
                        handleChange={handleChange}
                        error={error}
                        handleInvalid={handleInvalid}
                      ></ContactInformation>
                      <Grid sx={{ mt: 2 }} container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <FormControlLabel
                            sx={{ alignItems: "flex-start" }}
                            control={
                              <Checkbox
                                sx={{ pt: 0 }}
                                color="primary"
                                name="one "
                                value={acceptAgreement}
                                onChange={(event) =>
                                  setAcceptAgreement(event.target.checked)
                                }
                              />
                            }
                            label={
                              <>
                                <Typography variant="bodyCopyRegular">
                                  By ticking the box, you confirm that you agree
                                  with{" "}
                                </Typography>
                                <Typography variant="bodyCopyBold">
                                  <Link
                                    href="/agreement-policies"
                                    target="_blank"
                                    underline="always"
                                    color={"inherit"}
                                    sx={{
                                      mr: 1,
                                      "&:hover": {
                                        cursor: "pointer",
                                      },
                                    }}
                                  >
                                    Neta’s Fund agreement policies
                                  </Link>
                                </Typography>
                              </>
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormControlLabel
                            sx={{ alignItems: "flex-start" }}
                            control={
                              <Checkbox
                                sx={{ pt: 0 }}
                                color="primary"
                                value={acceptPolicy}
                                name="two "
                                onChange={(event) =>
                                  setAcceptPolicy(event.target.checked)
                                }
                              />
                            }
                            label={
                              <>
                                <Typography variant="bodyCopyRegular">
                                  By ticking the box, you confirm that you agree
                                  with{" "}
                                </Typography>
                                <Typography variant="bodyCopyBold">
                                  <Link
                                    href="/policy"
                                    target="_blank"
                                    underline="always"
                                    color={"inherit"}
                                    sx={{
                                      mr: 1,
                                      "&:hover": {
                                        cursor: "pointer",
                                      },
                                    }}
                                  >
                                    Neta’s privacy policy
                                  </Link>
                                </Typography>
                              </>
                            }
                          />
                        </Grid>
                      </Grid>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          type="submit"
                          disabled={!acceptAgreement || !acceptPolicy || data}
                        >
                          Confirm
                        </Button>
                      </Box>
                    </div>
                  </Box>
                </Container>
              </React.Fragment>
            </Box>
          </Container>
        </>
      )}
    </Layout>
  );
};
