import { GetFundRolesApi, GetFundRolesQuery, GetUsersInvitationsQuery, IsUserInviteExpiredAPI, deleteUserFromFundAPI, deleteUserInviteAPI, getFundRoleAPI, getUserInviteAPI, getUsersInvitationsAPI, inviteUserToFundAPI, manageUserInvitationAPI, updateUserInvitationAPI, updateUserRoleAPI } from "api/fund-account-invitation.api";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { FundUsersRoles } from "types/FundUsersRoles";
import PaginatedEntityList from "types/PaginatedEntityList";
import { FundAccountInvitation, FundAccountInvitationInput, USER_STATUS } from "types/fund-account-invitation";

const FUND_ACCOUNT_INVITATIONS_RQ_KEY = "FundAccountInvitations";


export const useInviteUserToFundMutation = () => {
  return useMutation<unknown, unknown, FundAccountInvitationInput>({
    mutationKey: [FUND_ACCOUNT_INVITATIONS_RQ_KEY],
    mutationFn: (data) => inviteUserToFundAPI(data),
  })
}
export const useUpdateUserInvitationMutation = (id: number) => {
  return useMutation<unknown, unknown, {
    email: string;
    role: string;
  }>({
    mutationKey: [FUND_ACCOUNT_INVITATIONS_RQ_KEY, id],
    mutationFn: (data) => updateUserInvitationAPI(id, data),
  })
}
export const useManageUserInvitationMutation = () => {
  return useMutation<unknown, unknown, {
    invitationId: number;
    status: USER_STATUS;
  }>({
    mutationKey: [FUND_ACCOUNT_INVITATIONS_RQ_KEY],
    mutationFn: (data) => manageUserInvitationAPI(data),
  })
}
export const useUpdateUserRoleMutation = (id: number) => {
  return useMutation<unknown, unknown, {
    role: string;
  }>({
    mutationKey: [FUND_ACCOUNT_INVITATIONS_RQ_KEY, id],
    mutationFn: (data) => updateUserRoleAPI(id, data),
  })
}
export const useDeleteUserInvitationMutation = () => {
  return useMutation<unknown, unknown, number>({
    mutationKey: [FUND_ACCOUNT_INVITATIONS_RQ_KEY],
    mutationFn: (data) => deleteUserInviteAPI(data),
  })
}
export const useDeleteUserFromFundMutation = () => {
  return useMutation<unknown, unknown, number>({
    mutationKey: [FUND_ACCOUNT_INVITATIONS_RQ_KEY],
    mutationFn: (data) => deleteUserFromFundAPI(data),
  })
}
export const useGetUsersInvitationsQueryWithPagination = (query: GetUsersInvitationsQuery) => {
  return useInfiniteQuery<PaginatedEntityList<FundAccountInvitation>>({
    queryKey: [FUND_ACCOUNT_INVITATIONS_RQ_KEY, query],
    enabled: query.filter?.fundId !== 0,
    queryFn: ({ pageParam = 0 }) => getUsersInvitationsAPI({ ...query, offset: pageParam }),
    getNextPageParam: (lastPage) => { return lastPage.pagination.nextOffset },
  })
}
export const useGetUsersInvitationsQuery = (query: GetUsersInvitationsQuery) => {
  return useQuery<PaginatedEntityList<FundAccountInvitation>>({
    queryKey: [FUND_ACCOUNT_INVITATIONS_RQ_KEY, query],
    enabled: query.filter?.email !== undefined,
    queryFn: () => getUsersInvitationsAPI({ ...query }),
  })
}
export const useGetFundRolesQuery = (query: GetFundRolesQuery) => {
  return useInfiniteQuery<PaginatedEntityList<FundUsersRoles>>({
    queryKey: [FUND_ACCOUNT_INVITATIONS_RQ_KEY, query],
    enabled: query.fundId !== 0,
    queryFn: ({ pageParam = 0 }) => GetFundRolesApi({ ...query, offset: pageParam }),
    getNextPageParam: (lastPage) => { return lastPage.pagination.nextOffset },
  })
}
export const useGetUserInviteQuery = (id: number) => {
  return useQuery<FundAccountInvitation>({
    queryKey: [FUND_ACCOUNT_INVITATIONS_RQ_KEY, id],
    enabled: id !== 0,
    queryFn: () => getUserInviteAPI(id),
  })
}
export const useIsUserInviteExpiredQuery = (id: number) => {
  return useQuery<boolean>({
    queryKey: [FUND_ACCOUNT_INVITATIONS_RQ_KEY, id],
    enabled: id !== 0,
    queryFn: () => IsUserInviteExpiredAPI(id),
  })
}

export const useGetFundRoleQuery = (id: number) => {
  return useQuery<FundUsersRoles>({
    queryKey: [FUND_ACCOUNT_INVITATIONS_RQ_KEY, id],
    enabled: id !== 0,
    queryFn: () => getFundRoleAPI(id),
  })
}
