import awsExports from "../aws-exports";
import axios, { AxiosResponse } from "axios";
import { fetchAuthSession, signOut } from "aws-amplify/auth";
const apiHttpClient = axios.create({
  baseURL: awsExports.endpoints[0].endpoint,
});

apiHttpClient.interceptors.request.use(async (reqConfig) => {
  try {
    const loggedIn = localStorage.getItem("loggedIn");

    const { idToken } =
      (await fetchAuthSession({ forceRefresh: true })).tokens ?? {};
    if (loggedIn === "true" && idToken === undefined) {
      signOut();
      localStorage.clear();
      window.location.replace("/signIn");
    } else {
      reqConfig.headers.Authorization = `Bearer ${
        idToken?.toString() as string
      }`;
    }
    return reqConfig;
  } catch (refreshError) {
    console.log("Refresh token expired. Logging out.", refreshError);
    signOut();
  }

  return reqConfig;
});

apiHttpClient.interceptors.response.use((res: AxiosResponse) => res.data);

export default apiHttpClient;
