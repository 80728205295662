import { SignupUserAPI, addUserAPI, getUserAPI, getUserByEmailAPI, updateUserAPI } from "api/user.api";
import { useMutation, useQuery } from "react-query";
import { User, UserInput } from "types/user";

const USERS_RQ_KEY = "users";


export const useAddUserToFundMutation = () => {
  return useMutation<unknown, unknown, UserInput>({
    mutationKey: [USERS_RQ_KEY],
    mutationFn: (data) => addUserAPI(data),
  })
}

export const useUpdateUserMutation = () => {
  return useMutation<unknown, unknown, UserInput>({
    mutationKey: [USERS_RQ_KEY],
    mutationFn: (data) => updateUserAPI(data),
  })
}
export const useSignupUserMutation = () => {
  return useMutation<unknown, unknown, UserInput>({
    mutationKey: [USERS_RQ_KEY],
    mutationFn: (data) => SignupUserAPI(data),
  })
}

export const useGetUserQuery = (sub: string) => {
  return useQuery<User>({
    queryKey: [USERS_RQ_KEY, sub],
    enabled: sub !== null,
    queryFn: () => {
      if (sub) {
        return getUserAPI(sub)
      }
      else {
        return null as any
      }
    },
  })

}
export const useGetUserByEmailQuery = (email: string) => {
  return useQuery<User>({
    queryKey: [USERS_RQ_KEY, email],
    enabled: email !== null && email !== '',
    queryFn: () => getUserByEmailAPI(email),
  })

}

