import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Layout from "components/layout/layout";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import { useContext, useMemo } from "react";
import { moneyFormat } from "utils/money-format";
import { primary, warm } from "style/color";
import { ReactComponent as GrantVestmentsIcon } from "./../../assets/Icons/Gantvestments.svg";
import React from "react";
import {
  useGetFundBackedGrantVestmentQuery,
  useGetFundBackedGrantVestmentTotalQuery,
} from "hooks/use-services/fund-backed-grant-vestment.service";
import FundBackedGrantVestmentCard from "./fund-backed-grant-vestment-card";
import { FundBackedGrantVestment } from "types/fund-backed-grant-vestment";
import useIntersectionObserver from "hooks/use-services/useIntersectionObserver";
import { useNavigate } from "react-router-dom";
import { GrantVestmentsHomePage } from "constants/pages";
// import { useGetGrantVestmentsQuery } from "hooks/use-services/grant-vestment.service";

const FundBackedGrantVestmentList = () => {
  const navigate = useNavigate();
  const { selectedAccount } = useContext<AuthData>(AuthContext);
  const [limit] = React.useState<number>(1);

  const [viewAll] = React.useState<boolean>(true);
  const {
    data: paginatedData,
    fetchNextPage,
    isSuccess,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  } = useGetFundBackedGrantVestmentQuery({
    fundId: selectedAccount?.fundAccountId! || 0,
    limit: limit,
  });
  let myGrantvestments = useMemo<FundBackedGrantVestment[]>(
    () =>
      paginatedData ? paginatedData?.pages?.flatMap((item) => item.data) : [],
    [paginatedData]
  );

  const lastElementRef = useIntersectionObserver({
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
  });
  const { data, isSuccess: isSuccessTotal } =
    useGetFundBackedGrantVestmentTotalQuery({
      fundId: (selectedAccount?.fundAccountId as number) || 0,
    });
  //   const { data: grantvestmentList } = useGetGrantVestmentsQuery({
  //     filters: {
  //       sort: "Newest",
  //       show: "All",
  //     },
  //   });
  //   const handleViewAll = () => {
  //     setViewAll(!viewAll);
  //   };
  return (
    <Layout>
      {() => (
        <>
          <Box sx={{ m: 5 }}>
            <Grid
              sx={{ mb: 2, mt: 2, alignItems: "center" }}
              container
              spacing={2}
            >
              <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
                <Typography variant="h3" sx={{ color: "neutral.main" }}>
                  My GrantVestmentsᵀᴹ
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="smallCopy">TOTAL VALUE</Typography>
                <Typography variant="h2" sx={{ color: primary[900] }}>
                  ${moneyFormat(isSuccessTotal ? data?.totalAmount : 0)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Button
                  onClick={() => navigate(GrantVestmentsHomePage)}
                  startIcon={<GrantVestmentsIcon />}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Browse all GrantVestments
                </Button>
              </Grid>
            </Grid>
            <Divider
              sx={{ backgroundColor: warm[600], borderWidth: "1px", mt: 7 }}
            />
            <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
              <Typography
                className="title"
                variant="subtitle2"
                sx={{ color: "neutral.main", mr: 3 }}
              >
                Backed projects{" "}
              </Typography>
              {/* until we get back section interested in */}
              {/* <Button
                onClick={handleViewAll}
                className="link"
                variant="text"
                color="primary"
                size="large"
              >
                {viewAll ? "View less" : "View all"}
              </Button> */}
            </Box>
            <Grid
              sx={{ mb: 2, mt: 2, alignItems: "center" }}
              container
              spacing={2}
            >
              {isSuccess &&
                myGrantvestments &&
                myGrantvestments.map(
                  (item: FundBackedGrantVestment, index: number) => {
                    return (
                      <Grid key={index} item xs={12} sm={12} md={12}>
                        <FundBackedGrantVestmentCard
                          grantvestment={item?.grantVestment}
                          grantVestmentRequestStatue={
                            item.grantVestmentRequestStatue
                          }
                          backedAmount={item.backedAmount}
                        />
                      </Grid>
                    );
                  }
                )}
              {viewAll && (
                <Box ref={lastElementRef}>
                  {isFetchingNextPage && (
                    <Grid
                      item
                      xs={12}
                      sm={10}
                      textAlign={"center"}
                      sx={{ m: 3 }}
                    >
                      {" "}
                      <CircularProgress color="primary" />
                    </Grid>
                  )}
                </Box>
              )}
            </Grid>
          </Box>
          {/* {
                        !viewAll && <>
                            <Box sx={{ p: 5, backgroundColor: warm[500] }}>
                                <Typography className="title" variant='subtitle2' sx={{ color: 'neutral.main', mb: 3 }} >
                                    You might also be interested in</Typography>
                                {
                                    grantvestmentList?.data &&
                                    <GrantVestmentsCard grantvestment={grantvestmentList?.data[0]} single={true} allContent={false} />
                                }


                            </Box>
                            <Box sx={{ p: 5 }}>
                            </Box>
                        </>
                    } */}
        </>
      )}
    </Layout>
  );
};

export default FundBackedGrantVestmentList;
