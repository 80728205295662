import { useContext, useEffect } from "react";
import Layout from "../../components/layout/layout";
import { Box, Typography } from "@mui/material";

import { AuthContext } from "../../providers/userProvider";
import { useLocation } from "react-router-dom";
import {
  GRANTVESTMENT_STATUS,
} from "../../types/grant-vestment";
import { AuthData } from "types/auth";
import GrantVestmentsDetailsCard from "../../components/grant-vestments/grant-vestment-details/grant-vestment-details-card";
import GrantVestmentsDetailsTags from "../../components/grant-vestments/grant-vestment-details/grant-vestment-details-tags";
import GrantVestmentsDetailsTitles from "../../components/grant-vestments/grant-vestment-details/grant-vestment-details-titles";
import GrantVestmentsDetailsTabs from "../../components/grant-vestments/grant-vestment-details/grant-vestment-details-tabs";
import { warm } from "style/color";
import { moneyFormat } from "utils/money-format";
import { ReactComponent as ArrowUpIcon } from "./../../assets/Icons/arrow-up.svg";
import { useGetFundBackedGrantVestmentTotalQuery } from "hooks/use-services/fund-backed-grant-vestment.service";
import { useGetGrantVestmentQuery } from "hooks/use-services/grant-vestment.service";
import mixpanel from "mixpanel-browser";

export const FundBackedGrantVestmentDetails = () => {
  const { state } = useLocation();
  const { selectedAccount } = useContext<AuthData>(AuthContext);
  const { userData } = useContext<AuthData>(AuthContext);
  const { data: grantvestment } = useGetGrantVestmentQuery({
    grantVestmentId: parseInt(state.id || "0"),
  });
  const { data, isSuccess } = useGetFundBackedGrantVestmentTotalQuery({
    fundId: (selectedAccount?.fundAccountId as number) || 0,
    grantvestmentId: state.id,
  });
  useEffect(() => {
    if (!mixpanel) {
      // Will be `undefined` if a token was not provided to `MixpanelProvider`
      return;
    }
    mixpanel.track('UserOpenedGrantVestmentPage', {
      grantvestment: grantvestment?.name,
      user: (userData?.user) ? `${userData.user.firstName} ${userData.user.lastName}` : '',
      email: (userData?.user) ? `${userData.user.email}` : '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantvestment?.name]);
  const track = (type: string, name: string, link: string) => {
    const title = (type === 'dataRoom') ? 'UserOpenedGrantVestmentDataRoomLink' : 'UserOpenedGrantVestmentUpdateLink'
    mixpanel.track(title, {
      grantvestment: grantvestment?.name,
      title: name,
      url: link,
      user: (userData?.user) ? `${userData.user.firstName} ${userData.user.lastName}` : '',
      email: (userData?.user) ? `${userData.user.email}` : '',
    });
  }
  return (
    <Layout>
      {(handleOpen: (value: boolean) => void) => (
        <Box sx={{ m: 4 }}>
          {grantvestment && (
            <>
              {/* details-titles */}
              <GrantVestmentsDetailsTitles grantvestment={grantvestment!} />
              {/* request-details */}
              {isSuccess && (
                <Box
                  sx={{
                    backgroundColor: warm[500],
                    padding: 2,
                    borderRadius: "4px",
                    my: 3,
                  }}
                >
                  {grantvestment.status === GRANTVESTMENT_STATUS.RAISING && (
                    <Typography
                      sx={{
                        color: "neutral.main",
                        display: "grid",
                        justifyContent: "center",
                      }}
                      variant="subtitle3"
                    >
                      You are already backing this project with a{" "}
                      {moneyFormat(data?.totalAmount)} GrantVestment.
                    </Typography>
                  )}
                  {grantvestment.status === GRANTVESTMENT_STATUS.CLOSED && (
                    <>
                      <Typography
                        className="title"
                        variant="smallCopy"
                        sx={{ color: "neutral.main" }}
                        gutterBottom
                      >
                        Your grantvestment value
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "neutral.main", mr: 1 }}
                        >
                          $
                          {moneyFormat(
                            data?.totalAmount! * grantvestment?.moicValue!
                          )}
                        </Typography>
                        <ArrowUpIcon />

                        <Typography
                          variant="smallCopyBold"
                          sx={{ color: "neutral.main" }}
                        >
                          MOIC {grantvestment?.moicValue}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              )}
              {/* details-card */}
              <GrantVestmentsDetailsCard
                handleOpen={handleOpen}
                grantvestment={grantvestment!}
                isAuthenticated={userData?.user !== null}
                buttonLabel="Back this project"
                hideProgressBar={true}
              />
              {/* details-tags */}
              <GrantVestmentsDetailsTags grantvestment={grantvestment!} />
              {/* details-tabs */}
              <GrantVestmentsDetailsTabs
                track={track}
                grantvestment={grantvestment!}
                isAuthenticated={userData?.user !== null}
                handleOpen={handleOpen}
              />
            </>
          )}
        </Box>
      )}
    </Layout>
  );
};
