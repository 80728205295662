import React, { useContext, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Step,
  StepLabel,
  Stepper,
  SvgIcon,
  Typography,
} from "@mui/material";
import Layout from "components/layout/layout";
import { AuthContext } from "providers/userProvider";
import { neutral, warm } from "style/color";
import { AuthData } from "types/auth";
import { moneyFormat } from "utils/money-format";
import { ReactComponent as CheckIcon } from "./../../../assets/Icons/cheque.svg";
import { ReactComponent as TransferOutgoingIcon } from "./../../../assets/Icons/transfer-outgoing.svg";

import {
  ContributionTransactionInput,
  TRANSACTION_TYPE_ENUM,
  PAYMENT_METHOD_ENUM,
  CashInformation,
  SecuritiesInformation,
} from "types/contribution-transaction";
import { ContributionTransactionDetails } from "../../../components/contribution-transaction/contribution-details";
import { DonorInformation } from "../../../components/contribution-transaction/donor-information";
import { PaymentMethodDetails } from "../../../components/contribution-transaction/payment-method";
import { SuccessStep } from "./success-step";
import { BackToPreviousButton } from "components/shared/back-to-previous-button";
import { useAddContributionTransactionMutation } from "hooks/use-services/contribution-transaction.service";
import { NetaSetting } from "constants/neta-settings";
import { NetaSettingContext } from "providers/netaSettingsProvider";

export const AddMoney = () => {
  const { selectedAccount, userData } = useContext<AuthData>(AuthContext);
  const { contributionMinimumAmount } =
    useContext<NetaSetting>(NetaSettingContext);
  const addContributionTransaction = useAddContributionTransactionMutation(
    selectedAccount?.fundAccountId || 0
  );
  const [acceptPolicy, setAcceptPolicy] = React.useState(false);
  const [contributionTransaction, setContributionTransaction] =
    React.useState<ContributionTransactionInput>({
      sub: "",
      type: TRANSACTION_TYPE_ENUM.CASH,
      contributionInformation: {
        requestedAmount: 0,
        paymentMethod: PAYMENT_METHOD_ENUM.TRANSFER,
      },
      contributionReport: {
        name: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: "",
        donorEmail: "",
      },
    });
  const steps = ["Enter details", "Confirmation"];
  const [activeStep, setActiveStep] = React.useState(0);
  useEffect(() => {
    if (selectedAccount && userData) {
      let report = {
        name: `${userData.user?.firstName} ${userData.user?.lastName}`,
        addressLine1: userData.user?.addressLine1!,
        addressLine2: userData.user?.addressLine2!,
        city: userData.user?.city!,
        state: userData.user?.state!,
        zip: userData.user?.zip!,
      };
      if (selectedAccount.entityInformation) {
        report = {
          name: selectedAccount.entityInformation?.entityName!,
          addressLine1: selectedAccount.entityInformation?.firstAddressLine!,
          addressLine2: selectedAccount.entityInformation?.secondAddressLine!,
          city: selectedAccount.entityInformation?.city!,
          state: selectedAccount.entityInformation?.state!,
          zip: selectedAccount.entityInformation?.zip!,
        };
      }
      setContributionTransaction({
        ...contributionTransaction,
        sub: userData.sub!,
        contributionReport: {
          ...report,
          donorEmail: userData.user?.email!,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, userData]);
  const handleChange = (key: string, value: any) => {
    if (key === "type") {
      setAcceptPolicy(false);
    }
    setContributionTransaction({
      ...contributionTransaction,
      [key]: value,
    });
  };
  const hasInformation = () => {
    if (
      contributionTransaction.type === TRANSACTION_TYPE_ENUM.CASH &&
      (contributionTransaction.contributionInformation as CashInformation)
        .requestedAmount !== ("" as any)
    ) {
      return 1;
    }
    if (
      contributionTransaction.type === TRANSACTION_TYPE_ENUM.SECURITIES &&
      (contributionTransaction.contributionInformation as SecuritiesInformation)
        .securityName !== "" &&
      (contributionTransaction.contributionInformation as SecuritiesInformation)
        .ticker !== "" &&
      (contributionTransaction.contributionInformation as SecuritiesInformation)
        .quantity !== ("" as any)
    ) {
      return 2;
    }
    return 0;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let newCont = contributionTransaction;
    if (contributionTransaction.type === TRANSACTION_TYPE_ENUM.CASH) {
      (newCont.contributionInformation as CashInformation).requestedAmount = +(
        contributionTransaction.contributionInformation as CashInformation
      ).requestedAmount;
    }
    if (contributionTransaction.type === TRANSACTION_TYPE_ENUM.SECURITIES) {
      (newCont.contributionInformation as SecuritiesInformation).quantity = +(
        contributionTransaction.contributionInformation as SecuritiesInformation
      ).quantity;
    }
    addContributionTransaction.mutate(contributionTransaction, {
      onSuccess: (updated) => {
        setActiveStep(1);
      },
    });
  };
  const minAmountError = () => {
    if (contributionTransaction.type === TRANSACTION_TYPE_ENUM.CASH) {
      if (
        (contributionTransaction.contributionInformation as CashInformation)
          .requestedAmount !== 0 &&
        +(contributionTransaction.contributionInformation as CashInformation)
          .requestedAmount < contributionMinimumAmount
      ) {
        return true;
      } else {
        return false;
      }
    }
  };
  console.log(contributionTransaction);
  return (
    <Layout>
      {() => (
        <>
          <Box sx={{ my: 4, width: "100%" }}>
            <React.Fragment>
              <Box sx={{ mt: 3, mx: 3 }}>
                {activeStep === 0 && <BackToPreviousButton />}
                <Grid sx={{ mb: 5, mt: 2 }} container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h3">
                      Add assets to your Fund
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="bodyCopyRegular">
                      Please use this form to tell us about a contribution to
                      your Fund, so that we can properly track it and credit it
                      to your Fund. Once payment/assets are received, they will
                      be reflected in your balance.{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={5} sx={{ mt: 3 }}>
                    <Stepper activeStep={activeStep}>
                      {steps.map((label, index) => {
                        return (
                          <Step key={label}>
                            <StepLabel>
                              <Typography variant="smallCopy" color="neutral">
                                Step {index + 1}
                              </Typography>
                              <br />
                              <Typography variant="subtitle3">
                                {label}
                              </Typography>
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Grid>
                </Grid>
                {activeStep === 0 && (
                  <Box
                    component="form"
                    autoComplete="off"
                    onSubmit={(event: any) => handleSubmit(event)}
                  >
                    <Grid sx={{ mb: 5, mt: 2 }} container spacing={2}>
                      <Grid item xs={12} sm={8}>
                        <ContributionTransactionDetails
                          contributionTransaction={contributionTransaction as any}
                          handleChange={handleChange}
                        />
                        <PaymentMethodDetails
                          information={
                            contributionTransaction.type ===
                              TRANSACTION_TYPE_ENUM.CASH
                              ? (contributionTransaction.contributionInformation as CashInformation)
                              : null
                          }
                          transactionType={contributionTransaction.type}
                          handleChange={handleChange}
                        />
                        {contributionTransaction.type !==
                          TRANSACTION_TYPE_ENUM.OTHER &&
                          (
                            contributionTransaction.contributionInformation as CashInformation
                          ).paymentMethod !== PAYMENT_METHOD_ENUM.DAF && (
                            <DonorInformation
                              newDonorDisabled={
                                contributionTransaction.type ===
                                TRANSACTION_TYPE_ENUM.CASH &&
                                (
                                  contributionTransaction.contributionInformation as CashInformation
                                ).paymentMethod === PAYMENT_METHOD_ENUM.DAF
                              }
                              contributionReport={
                                contributionTransaction.contributionReport
                              }
                              handleChange={handleChange}
                            />
                          )}
                      </Grid>
                      <Grid sx={{ mt: 2 }} item xs={12} sm={4}>
                        {contributionTransaction.type !==
                          TRANSACTION_TYPE_ENUM.OTHER && (
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12}>
                                <Card sx={{ boxShadow: "none" }}>
                                  <CardContent
                                    sx={{ p: 5, "&:last-child": { pb: 5 } }}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      sx={{ color: `neutral.main` }}
                                      gutterBottom
                                    >
                                      Summary
                                    </Typography>
                                    <Typography
                                      className="title"
                                      variant="smallCopy"
                                      sx={{ color: `neutral.main` }}
                                      paragraph
                                    >
                                      {hasInformation() !== 0
                                        ? "Transfer of"
                                        : "Add assets"}
                                    </Typography>

                                    {hasInformation() === 1 && (
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ color: `primary.main` }}
                                        gutterBottom
                                      >
                                        $
                                        {moneyFormat(
                                          +(
                                            contributionTransaction.contributionInformation as CashInformation
                                          ).requestedAmount
                                        )}
                                      </Typography>
                                    )}
                                    {hasInformation() === 2 && (
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ color: `primary.main` }}
                                      >
                                        {moneyFormat(
                                          +(
                                            contributionTransaction.contributionInformation as SecuritiesInformation
                                          ).quantity
                                        )}
                                        <Typography
                                          variant="bodyCopyBold"
                                          sx={{ color: `primary.main`, ml: 1 }}
                                        >
                                          of{" "}
                                          {
                                            (
                                              contributionTransaction.contributionInformation as SecuritiesInformation
                                            ).ticker
                                          }
                                        </Typography>
                                      </Typography>
                                    )}

                                    <Typography
                                      variant="bodyCopyRegular"
                                      sx={{ color: `neutral.main` }}
                                    >
                                      To {selectedAccount?.fundAccountName}
                                    </Typography>
                                    {hasInformation() === 1 && (
                                      <>
                                        <Divider
                                          sx={{
                                            backgroundColor: warm[600],
                                            borderWidth: "1px",
                                            my: 2,
                                          }}
                                        />
                                        <Typography
                                          className="title"
                                          variant="smallCopy"
                                          sx={{ color: `neutral.main` }}
                                          paragraph
                                        >
                                          via
                                        </Typography>
                                        <Typography
                                          variant="bodyCopyBold"
                                          sx={{
                                            color: `neutral.main`,
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          paragraph
                                        >
                                          {(
                                            contributionTransaction.contributionInformation as CashInformation
                                          ).paymentMethod ===
                                            PAYMENT_METHOD_ENUM.TRANSFER && (
                                              <>
                                                <SvgIcon
                                                  sx={{
                                                    mr: 2,
                                                    "&  svg  path": {
                                                      fill: neutral[900],
                                                    },
                                                    "&  svg  circle": {
                                                      stroke: neutral[900],
                                                    },
                                                  }}
                                                >
                                                  {" "}
                                                  <TransferOutgoingIcon />
                                                </SvgIcon>{" "}
                                                Transfer{" "}
                                              </>
                                            )}
                                          {(
                                            contributionTransaction.contributionInformation as CashInformation
                                          ).paymentMethod ===
                                            PAYMENT_METHOD_ENUM.DAF && (
                                              <>
                                                <SvgIcon
                                                  sx={{
                                                    mr: 2,
                                                    "&  svg  path": {
                                                      fill: neutral[900],
                                                    },
                                                    "&  svg  circle": {
                                                      stroke: neutral[900],
                                                    },
                                                  }}
                                                >
                                                  {" "}
                                                  <TransferOutgoingIcon />
                                                </SvgIcon>{" "}
                                                From another DAF{" "}
                                              </>
                                            )}
                                          {(
                                            contributionTransaction.contributionInformation as CashInformation
                                          ).paymentMethod ===
                                            PAYMENT_METHOD_ENUM.CHECK && (
                                              <>
                                                <SvgIcon
                                                  sx={{
                                                    mr: 2,
                                                    "&  svg  path": {
                                                      fill: neutral[900],
                                                    },
                                                    "&  svg  circle": {
                                                      stroke: neutral[900],
                                                    },
                                                  }}
                                                >
                                                  {" "}
                                                  <CheckIcon />
                                                </SvgIcon>{" "}
                                                Check{" "}
                                              </>
                                            )}
                                        </Typography>
                                      </>
                                    )}
                                  </CardContent>
                                </Card>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <FormControlLabel
                                  sx={{ alignItems: "flex-start" }}
                                  control={
                                    <Checkbox
                                      sx={{ pt: 0 }}
                                      color="primary"
                                      checked={acceptPolicy}
                                    />
                                  }
                                  value={acceptPolicy}
                                  name="acceptPolicy"
                                  onChange={(event: any) => {
                                    event.stopPropagation();
                                    setAcceptPolicy(event.target.checked);
                                  }}
                                  label={
                                    <>
                                      <Typography variant="bodyCopyRegular">
                                        I confirm to have already proceeded to the
                                        transfer indicated above
                                      </Typography>
                                    </>
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  type="submit"
                                  disabled={!acceptPolicy || minAmountError()}
                                >
                                  Confirm
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {activeStep === 1 && (
                  <SuccessStep
                    contributionTransaction={contributionTransaction}
                  />
                )}
              </Box>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
