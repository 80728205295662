import { Investment } from "./Investment";
import { Account } from "./account";
import { User } from "./user";
export enum INVESTMENT_REQUEST_STATUS_ENUM {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}
export enum INVESTMENT_REQUEST_TYPE_ENUM {
  BUY = "BUY",
  SELL = "SELL",
  DIVIDEND = "DIVIDEND",
}
export enum INVESTMENT_REQUEST_INTEREST_ENUM {
  UNITS = "UNITS",
  AMOUNT = "AMOUNT",
}
export interface InvestmentRequest {
  investmentRequestId: number;
  investment: Investment;
  fund: Account;
  user: User;
  status: INVESTMENT_REQUEST_STATUS_ENUM;
  type: INVESTMENT_REQUEST_TYPE_ENUM;
  requestedAmount: number;
  requestedUnits: number;
  appliedAmount: number;
  appliedUnits: number;
  rejectionReason?: string;
  interestedIn: INVESTMENT_REQUEST_INTEREST_ENUM;
  createdAt: Date;
  executionDate?: Date;
}
export interface InvestmentRequestInput {
  fundId: number;
  type: INVESTMENT_REQUEST_TYPE_ENUM;
  requestedAmount: number;
  requestedUnits: number;
  interestedIn: INVESTMENT_REQUEST_INTEREST_ENUM;
}
