import {
  Box,
  FilledInput,
  Grid,
  InputAdornment,
  Typography,
  FormControl,
  CircularProgress,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import Layout from "components/layout/layout";
import { neutral } from "style/color";
import SearchIcon from "@mui/icons-material/Search";
import { useGetCharitiesQuery } from "hooks/use-services/charity.service";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import CharityCard from "components/charity-card/CharityCard";
import { Charity } from "types/charity";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import useIntersectionObserver from "hooks/use-services/useIntersectionObserver";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import { CharityGrantRequestPagePath } from "constants/pages";

const CharitiesSearchPage = () => {
  const navigate = useNavigate();
  const initialized = useRef(false);
  const { selectedAccount } = useContext<AuthData>(AuthContext);
  const [searchParams] = useSearchParams();
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState({
    limit: 6,
    offset: 0,
    searchTerm: "",
  });
  const {
    data,
    fetchNextPage,
    isSuccess: isGetCharitiesSuccess,
    hasNextPage,
    isLoading,
    isFetching,
    refetch,
    isFetchingNextPage,
  } = useGetCharitiesQuery(
    selectedAccount?.fundAccountId
      ? { ...query, activeFundAccountId: selectedAccount?.fundAccountId }
      : query
  );
  const charitiesData = useMemo<Charity[]>(
    () => (data ? data?.pages?.flatMap((item) => item.data) : []),
    [data]
  );
  const lastElementRef = useIntersectionObserver({
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
  });
  const refresh = () => {
    refetch();
  };
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (searchParams && searchParams.get("keyword")) {
        setQuery({
          ...query,
          offset: 0,
          searchTerm: searchParams.get("keyword")!,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handlekeywordChange = (event: any) => {
    const { value } = event.target;
    if (event.key === "Enter") {
      setQuery({
        ...query,
        offset: 0,
        searchTerm: value,
      });
    }
  };
  const reset = () => {
    navigate(-1);
  };
  const openRequestPage = (ein: string): void => {
    navigate({
      pathname: CharityGrantRequestPagePath,
      search: `?${createSearchParams({ ein: ein })}`,
    });
  };
  return (
    <Layout>
      {(handleOpen: (value: boolean) => void) => (
        <Box sx={{ m: 5 }}>
          <Box>
            <Typography variant="h3" color={neutral[900]}>
              Charities
            </Typography>
            <Typography variant="bodyCopyRegular">
              From your DAF you can grant to a wide variety of non-profits. Type
              their name or EIN in the search bar below to find the charity you
              want to support. You can then save them for later. If you don't
              find what you are looking for, please contact us and we can add
              new ones to the database.{" "}
            </Typography>
          </Box>
          <Grid
            sx={{ my: 2 }}
            container
            justifyContent={{
              xs: "start",
              md: query.searchTerm ? "space-between" : "end",
            }}
            alignItems="center"
            columns={16}
            spacing={{ xs: 1, md: 0 }}
            rowSpacing={2}
          >
            <Grid
              display={{ xs: "block", md: "none" }}
              item
              xs={16}
              md={4}
              textAlign={"end"}
            >
              <FormControl variant="outlined" fullWidth>
                <FilledInput
                  className="search"
                  placeholder="Search using the charity's name or its EIN"
                  size="small"
                  id="keyword"
                  value={keyword}
                  name="keyword"
                  onChange={(event) => setKeyword(event.target.value)}
                  onKeyDown={(event) => handlekeywordChange(event)}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            {query.searchTerm ? (
              <Grid item xs={6} md={2}>
                <FormControl size="small">
                  <OutlinedInput
                    value={query.searchTerm}
                    className="search"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={reset} edge="end">
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  ></OutlinedInput>
                </FormControl>
              </Grid>
            ) : (
              <Grid
                item
                xs={6}
                sx={{ display: { xs: "block", md: "none" } }}
              ></Grid>
            )}
            <Grid
              display={{ xs: "none", md: "block" }}
              item
              xs={16}
              md={4}
              textAlign={"end"}
            >
              <FormControl variant="outlined" fullWidth>
                <FilledInput
                  className="search"
                  placeholder="Type keyword or name"
                  size="small"
                  id="keyword"
                  value={keyword}
                  defaultValue={""}
                  name="keyword"
                  onChange={(event) => setKeyword(event.target.value)}
                  onKeyDown={(event) => handlekeywordChange(event)}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ marginY: 4 }}>
            {isGetCharitiesSuccess && (
              <>
                <Typography
                  sx={{ color: "neutral.main", mt: 4, mb: 2 }}
                  className="title"
                  variant="subtitle1"
                  gutterBottom
                >
                  {charitiesData.length} charities found
                </Typography>
                <Grid sx={{ mt: 2 }} container spacing={2}>
                  {charitiesData?.map((charity: any) => (
                    <Grid key={charity.ein} item xs={12} md={4}>
                      <CharityCard
                        selectCharity={openRequestPage}
                        handleOpen={handleOpen}
                        refresh={refresh}
                        charity={charity}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
            <Box ref={lastElementRef}>
              {isFetchingNextPage && (
                <Grid item xs={12} sm={10} textAlign={"center"} sx={{ m: 3 }}>
                  {" "}
                  <CircularProgress color="primary" />
                </Grid>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
};

export default CharitiesSearchPage;
