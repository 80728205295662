import React, { useContext, useEffect } from "react";
import Button from "@mui/material/Button";

import {
  AppBar,
  Box,
  Menu,
  Toolbar,
  MenuItem,
  Grid,
  Divider,
} from "@mui/material";
import { warm } from "../../style/color";
import logo from "../../assets/logo-black/logo-s.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AddFundPage,
  FundDashboardPage,
  GrantVestmentsHomePage,
  InvestmentsHomePagePath,
  MyFundsPageUrl,
  UserSettingsPage,
} from "../../constants/pages";
import { AuthContext } from "../../providers/userProvider";
import AddIcon from "@mui/icons-material/Add";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import { AuthData } from "types/auth";
interface HeaderProps {
  route: string;
  openSignIn: (signup: boolean) => void;
  signOut: (data?: any) => void

}
export const Header: React.FC<HeaderProps> = ({ route, signOut, openSignIn }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const anchorRef = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const { userData } = useContext<AuthData>(AuthContext);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };
  const open = (event: any) => {
    event.preventDefault();
    openSignIn(false);
  };

  const goToHome = () => {
    window.location.replace("https://www.netafoundation.org/");
  };
  const oneFund = () => {
    return userData?.user?.fundAccounts?.length === 1;
  };
  useEffect(() => {
    if (route === "authenticated") {
      setAnchorEl(anchorRef?.current!);
    }
  }, [route]);
  const getActive = (page: string) => {
    return location.pathname.includes(page);
  };
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          sx={{ boxShadow: "none", backgroundColor: warm[500] }}
          position="static"
          enableColorOnDark
        >
          <Toolbar>
            <Grid justifyContent="space-between" alignItems="center" container>
              <Grid item xs={12} sm={2}>
                <Box onClick={goToHome} component="img" alt="Logo" src={logo} />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Box
                  sx={{ color: warm[900], flexGrow: 1, display: "flex", ml: 5 }}
                >
                  <Button
                    variant={
                      getActive(GrantVestmentsHomePage)
                        ? "headeractive"
                        : "header"
                    }
                    onClick={() => navigate(GrantVestmentsHomePage)}
                  >
                    GrantVestmentᵀᴹ
                  </Button>
                  <Button
                    variant={
                      getActive("investments") ? "headeractive" : "header"
                    }
                    onClick={() => navigate(InvestmentsHomePagePath)}
                  >
                    Investments
                  </Button>
                  <Button
                    variant={getActive("charities") ? "headeractive" : "header"}
                    onClick={() => navigate("/charities")}
                  >
                    Charities
                  </Button>
                </Box>
              </Grid>
              <Grid sx={{ textAlign: "right" }} item xs={12} sm={2}>
                {!userData?.user && !location.pathname.includes("signup") && (
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    sx={{ backgroundColor: "secondary.dark" }}
                    onClick={(event) => open(event)}
                  >
                    Sign In
                  </Button>
                )}
                {userData?.user && !location.pathname.includes("signup") && (
                  <div>
                    <Button
                      ref={anchorRef as any}
                      size="large"
                      variant="contained"
                      color="secondary"
                      sx={{ backgroundColor: "secondary.dark" }}
                      onClick={handleClick}
                    >
                      {userData?.user?.firstName}
                    </Button>
                    {anchorEl && (
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={menuOpen}
                        onClose={handleClose}
                        sx={{
                          "& .MuiMenu-paper": { px: 2 },
                        }}
                      >
                        {oneFund() && (
                          <>
                            <MenuItem
                              sx={{ justifyContent: "flex-end", pr: 1 }}
                            >
                              <Button
                                startIcon={<AddIcon />}
                                size="medium"
                                variant="text"
                                onClick={() => navigate(AddFundPage)}
                              >
                                Add new fund
                              </Button>
                            </MenuItem>
                            <Divider />
                          </>
                        )}
                        {
                          oneFund() ?
                            <MenuItem className="user-dropdown"
                              onClick={() => navigate(FundDashboardPage)}>
                              Dashboard
                            </MenuItem> :
                            <MenuItem className="user-dropdown"
                              onClick={() => navigate(MyFundsPageUrl)}>
                              My funds
                            </MenuItem>
                        }
                        <MenuItem
                          className="user-dropdown"
                          onClick={() => navigate(UserSettingsPage)}
                        >
                          Personal settings
                        </MenuItem>
                        <MenuItem className="user-dropdown">Get help</MenuItem>
                        <Divider />
                        <MenuItem
                          sx={{ justifyContent: "flex-end", pr: 1 }}
                          onClick={signOut}
                        >
                          {" "}
                          <Button
                            startIcon={<LogoutSharpIcon />}
                            size="medium"
                            variant="outlined"
                          >
                            Logout
                          </Button>
                        </MenuItem>
                      </Menu>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}
export default Header;
