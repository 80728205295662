import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { CssBaseline } from "@mui/material";
import { theme } from "./style/theme";
import { Authenticator } from "@aws-amplify/ui-react";
import AppRouter from "./routing/Router";
import axios from "axios";
import UserContextProvider from "./providers/userProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { fetchAuthSession } from "aws-amplify/auth";
import NetaSettingContextProvider from "providers/netaSettingsProvider";
import mixpanel from 'mixpanel-browser';

Amplify.configure(awsExports);
//TODO : set the token on env vars
const MIXPANEL_TOKEN_DEV = 'd699b6449eda50f9aa96b766661a65ce';
const MIXPANEL_TOKEN_STAGING = '94ce3f8fd079c96fecff2146714889aa';
const MIXPANEL_TOKEN_PROD = '2ecd6466231d9b22502ddd4c34a8d43b';

const MIXPANEL_TOKEN = (awsExports.endpoints[0].endpoint.includes('prod')) ? MIXPANEL_TOKEN_PROD :
  (awsExports.endpoints[0].endpoint.includes('staging')) ? MIXPANEL_TOKEN_STAGING : MIXPANEL_TOKEN_DEV;
mixpanel.init(MIXPANEL_TOKEN, {
  persistence: "localStorage",
});
export const api = axios.create({
  baseURL: awsExports.endpoints[0].endpoint,
});

// TODO:: refactor axios client
api.interceptors.request.use(async (reqConfig) => {
  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  reqConfig.headers.Authorization = `Bearer ${idToken?.toString() as string}`;
  return reqConfig;
});

const queryClient = new QueryClient({});

function App() {
  return (
    <div className="App">
      {/* Mui provider */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* Amplify provider */}
        <Authenticator.Provider>
          {/* Routing */}

          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <UserContextProvider api={api}>
                <NetaSettingContextProvider>
                  <AppRouter />
                </NetaSettingContextProvider>
              </UserContextProvider>
            </QueryClientProvider>
          </BrowserRouter>
        </Authenticator.Provider>
      </ThemeProvider>
    </div>
  );
}
export default App;
