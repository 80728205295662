import React from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import ContributionTransaction, {
  CashInformation,
  PAYMENT_METHOD_ENUM,
  TRANSACTION_TYPE_ENUM,
} from "types/contribution-transaction";
import CloseIcon from "@mui/icons-material/Close";
import { SecuritiesPaymentDetails } from "../payment-details/securities-payment-details";
import { TransferCashPaymentDetails } from "../payment-details/transfer-cash-payment-details";
import { CheckCashPaymentDetails } from "../payment-details/check-cash-payment-details";
import { DafCashPaymentDetails } from "../payment-details/daf-cash-payment-details";

interface ViewPaymentDetailsProps {
  contributionTransaction: ContributionTransaction;
  open: boolean;
  onClose: () => void;
}
export const ViewPaymentDetails: React.FC<ViewPaymentDetailsProps> = ({
  open,
  onClose,
  contributionTransaction,
}) => {
  return (
    <>
      {contributionTransaction && (
        <Dialog
          sx={{ m: 2 }}
          fullWidth={false}
          maxWidth={"md"}
          open={open}
          onClose={onClose}
        >
          <DialogTitle sx={{ my: 2, pl: 4 }}>
            <Typography
              sx={{
                color: "neutral",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              variant="subtitle1"
            >
              Send{" "}
              {contributionTransaction?.type ===
                TRANSACTION_TYPE_ENUM.SECURITIES && "security transfer"}
              {contributionTransaction?.type === TRANSACTION_TYPE_ENUM.CASH &&
                (
                  contributionTransaction?.contributionInformation as CashInformation
                ).paymentMethod === PAYMENT_METHOD_ENUM.TRANSFER &&
                "cash transfer"}
              {contributionTransaction?.type === TRANSACTION_TYPE_ENUM.CASH &&
                (
                  contributionTransaction?.contributionInformation as CashInformation
                ).paymentMethod === PAYMENT_METHOD_ENUM.CHECK &&
                "cheque"}
              {contributionTransaction?.type === TRANSACTION_TYPE_ENUM.CASH &&
                (
                  contributionTransaction?.contributionInformation as CashInformation
                ).paymentMethod === PAYMENT_METHOD_ENUM.DAF &&
                "cash from another DAF"}
              {" "}
              using the data below
              <IconButton
                onClick={onClose}
                sx={{
                  padding: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                {" "}
                <CloseIcon />
              </IconButton>
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ pl: 4 }}>
            <Grid container spacing={2}>
              {contributionTransaction?.type ===
                TRANSACTION_TYPE_ENUM.SECURITIES && (
                  <SecuritiesPaymentDetails />
                )}
              {contributionTransaction?.type === TRANSACTION_TYPE_ENUM.CASH &&
                (
                  contributionTransaction?.contributionInformation as CashInformation
                ).paymentMethod === PAYMENT_METHOD_ENUM.TRANSFER && (
                  <TransferCashPaymentDetails />
                )}
              {contributionTransaction?.type === TRANSACTION_TYPE_ENUM.CASH &&
                (
                  contributionTransaction?.contributionInformation as CashInformation
                ).paymentMethod === PAYMENT_METHOD_ENUM.CHECK && (
                  <CheckCashPaymentDetails />
                )}
              {contributionTransaction?.type === TRANSACTION_TYPE_ENUM.CASH &&
                (
                  contributionTransaction?.contributionInformation as CashInformation
                ).paymentMethod === PAYMENT_METHOD_ENUM.DAF && (
                  <DafCashPaymentDetails />
                )}
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
