import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  Grid,
} from "@mui/material";

import { warm, neutral } from "style/color";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import SouthEastIcon from '@mui/icons-material/SouthEast';
import LaunchIcon from "@mui/icons-material/Launch";
import { Investment } from "types/Investment";
import { moneyFormat } from "utils/money-format";

import React, { useContext } from "react";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import { USER_ROLE } from "types/account";
import { USER_STATUS } from "types/fund-account-invitation";
interface Props {
  investment: Investment;
  buy: (ticker: string) => void;
  handleOpen?: any;
}
export const InvestmentCard: React.FC<Props> = ({
  investment,
  buy,
  handleOpen,
}) => {
  const { name, ticker, dailyChange, marketPrice, isListed, isAFP } =
    investment;
  const { userData, userRole } = useContext<AuthData>(AuthContext);
  return (
    <Card
      sx={{
        boxShadow: "none",
        ":hover": { backgroundColor: warm[500] },
      }}
    >
      <CardContent
        sx={{
          p: 5,
          "&:last-child": { pb: 5 },
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid alignItems="center" container>
          <Grid item xs={12} sm={4} alignItems="center">
            <Box>
              <Typography variant="bodyCopyBold">{name}</Typography>
              <Typography
                variant="bodyCopyRegular"
                sx={{
                  color: neutral[600],
                  display: "flex",
                  alignItems: "center",
                }}
                gutterBottom
              >
                {ticker}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography variant="bodyCopyBold">
                ${moneyFormat(marketPrice, 4)}
              </Typography>
              <Typography
                variant="bodyCopyRegular"
                sx={{
                  color: neutral[600],
                  display: "flex",
                  alignItems: "center",
                }}
                gutterBottom
              >

                {dailyChange >= 0
                  ? <><ArrowOutwardIcon fontSize="small" />{" "} +{moneyFormat(dailyChange, 2)}</>
                  : <><SouthEastIcon fontSize="small" />{" "}{moneyFormat(dailyChange, 2)}</>}
                %
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              disabled={!isListed}
              target="_blank"
              href={`https://finance.yahoo.com/quote/${ticker}`}
              startIcon={<LaunchIcon fontSize="small" />}
              variant="text"
              color="primary"
              className="charites-more-info"
            >
              More info
            </Button>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={
                userRole === USER_ROLE.VIEWER ||
                userData?.user?.status === USER_STATUS.PENDING ||
                !isAFP
              }
              onClick={() => {
                userData?.user ? buy(ticker) : handleOpen(false);
              }}
            >
              Buy
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
