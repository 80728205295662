import {
  Card,
  CardContent,
  Box,
  Typography,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  listItemClasses,
  Button,
} from "@mui/material";
import { DetailsButton } from "components/shared/custom-details-button";
import { useGetFutureGrantRequestsQuery } from "hooks/use-services/grant-request.service";
import { AuthContext } from "providers/userProvider";
import React, { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { neutral, warm } from "style/color";
import { AuthData } from "types/auth";
import {
  GrantRequest,
  GRANT_REQUEST_STATUS_ENUM,
  GRANT_REQUEST_TYPE_ENUM,
  getFrequency,
  FutureGrantRequest,
} from "types/grant-request";
import { shortFormat } from "utils/date-format";
import { moneyFormat } from "utils/money-format";
import { CancelGrant } from "./cancel-grant";
import { MyGrantsRequestsPagePath } from "constants/pages";
import { einFormat } from "types/charity";
interface MyScheduledGrantsRequestListProps {
  title: string;
  limit?: number
  dashboard?: boolean;

}
export const MyScheduledGrantsRequestList: React.FC<
  MyScheduledGrantsRequestListProps
> = ({ title, dashboard, limit = 30 }) => {
  const navigate = useNavigate();
  const { selectedAccount } = useContext<AuthData>(AuthContext);
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
  const [selectedGrant, setSelectedGrant] = useState<GrantRequest | null>();
  const { data: paginatedGrantRequests, refetch } = useGetFutureGrantRequestsQuery({
    limit: limit,
    filter: {
      fundAccountId: selectedAccount?.fundAccountId || 0,
      status: GRANT_REQUEST_STATUS_ENUM.SCHEDULED,
      statuses: [GRANT_REQUEST_STATUS_ENUM.SCHEDULED, GRANT_REQUEST_STATUS_ENUM.PENDING]
    },
  });

  const grantRequests = useMemo<FutureGrantRequest[]>(
    () =>
      paginatedGrantRequests
        ? paginatedGrantRequests?.pages?.flatMap((item) => item.data)
        : [],
    [paginatedGrantRequests]
  );
  const openEditPage = (grantRequest: GrantRequest) => {
    navigate({
      pathname: `/my-grants/edit/${grantRequest.grantRequestId}`,
    });
  };
  const handleOpenCancelDialog = (grantRequest: GrantRequest) => {
    setOpenCancelDialog(true);
    setSelectedGrant(grantRequest);
  };
  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
    refetch();
    setSelectedGrant(null);
  };
  const openDetailPage = (grantRequest: GrantRequest) => {
    navigate({
      pathname: `/my-grants/${grantRequest.grantRequestId}`,
    });
  };
  return (

    ((dashboard && grantRequests.length !== 0) || !dashboard) ?
      <Card sx={{ boxShadow: "none", p: 4 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: `neutral.main`, mb: 1, mr: 2 }} variant="subtitle3">
              {title}
              {dashboard && <Button
                onClick={() => navigate(MyGrantsRequestsPagePath)}
                className="link"
                variant="text"
                color="primary"
                size="large"
              >
                Manage
              </Button>}
            </Typography>
          </Box>
          <CancelGrant
            open={openCancelDialog}
            onClose={handleCloseCancelDialog}
            grantRequest={selectedGrant!}
          />
          {grantRequests.length !== 0 ? (
            <List
              className="successor"
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                mt: 2,
                [` & .${listItemClasses.root}:hover`]: {
                  backgroundColor: warm[200],
                },
              }}
            >
              {grantRequests.map((grantRequest, index) => {
                return (
                  <>
                    {" "}
                    <ListItem sx={{ display: "-webkit-inline-box" }} key={index}>
                      <Grid alignItems="center" container>
                        <Grid item xs={12} sm={2} textAlign={"start"}>
                          <ListItemText
                            sx={{
                              display: "inline-grid",
                              width: "auto",
                              flex: "none",
                              py: 1,
                            }}
                            primary={
                              <Typography
                                sx={{ color: `neutral.main` }}
                                variant="bodyCopyBold"
                                gutterBottom
                              >
                                {grantRequest.charityName}
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={2} textAlign={"center"}>
                          <Typography
                            variant="bodyCopyRegular"
                            sx={{ color: neutral[600] }}
                          >
                            {(grantRequest.charityCountry === "USA") ? einFormat(grantRequest.charityEin) : grantRequest.charityEin}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} textAlign={"center"}>
                          <Typography
                            variant="bodyCopyRegular"
                            sx={{ color: neutral[600] }}
                          >
                            {shortFormat(grantRequest.date)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} textAlign={"center"}>
                          {grantRequest.type ===
                            GRANT_REQUEST_TYPE_ENUM.RECURRENT ? (
                            <ListItemText
                              sx={{
                                display: "inline-grid",
                                width: "auto",
                                flex: "none",
                              }}
                              primary={
                                <Typography
                                  sx={{ color: neutral[600] }}
                                  variant="smallCopyBold"
                                  gutterBottom
                                >
                                  Every
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  sx={{ color: neutral[900] }}
                                  variant="bodyCopyRegular"
                                >
                                  {getFrequency(grantRequest.type, grantRequest.frequency)}
                                </Typography>
                              }
                            />
                          ) : (
                            <ListItemText
                              sx={{
                                display: "inline-grid",
                                width: "auto",
                                flex: "none",
                              }}
                              primary={
                                <Typography
                                  sx={{ color: neutral[900] }}
                                  variant="bodyCopyRegular"
                                >{`One Time`}</Typography>
                              }
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={1} textAlign={"center"}>

                          {grantRequest.status === GRANT_REQUEST_STATUS_ENUM.PENDING && (
                            <Button
                              sx={{
                                borderRadius: "4px",
                                p: 1,
                                backgroundColor: warm[200],
                                color: neutral[500],
                                paddingX: 2,
                                paddingY: 1.5,
                                fontFamily: "Quattrocento Sans",
                                fontSize: "12px",
                                fontWeight: "700",
                                lineHeight: "13.3px",
                              }}
                            >
                              PENDING
                            </Button>
                          )}
                        </Grid>
                        <Grid item xs={11} sm={2} textAlign={"end"}>
                          <Typography
                            sx={{ color: neutral[900] }}
                            variant="bodyCopyBold"
                            gutterBottom
                          >
                            <>
                              {" "}
                              -$
                              {moneyFormat(
                                grantRequest.amount
                              )}
                            </>
                          </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} textAlign={"end"}>
                          <DetailsButton
                            grant={true}
                            handleOpenEditPage={openEditPage}
                            item={grantRequest}
                            handleOpenDetails={openDetailPage}
                            handleOpenCancelDialog={handleOpenCancelDialog}
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                    {index !== grantRequests.length - 1 && (
                      <Divider key={index + 100} />
                    )}
                  </>
                );
              })}
            </List>
          ) : (
            <Typography
              sx={{ mt: 4, color: neutral[600] }}
              variant="bodyCopyRegular"
            >
              You don't have any scheduled grants.
            </Typography>
          )}
        </CardContent>
      </Card>
      :
      (
        <></>
      )

  )
};
