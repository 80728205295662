import apiHttpClient from "libs/api-http-client";
import { Account, Entity } from "types/account";

export const updateFundAccountAPI = (
  fundAccountId: number,
  data: {
    fundAccountName?: string;
    entityInformation?: Entity;
    interests?: number[];
  }
): Promise<unknown> => apiHttpClient.patch(`/funds/${fundAccountId}`, data);
export const addFundAccountAPI = (data: {
  userId: number;
  fund?: Account;
}): Promise<unknown> => apiHttpClient.post(`/funds/${data.userId}`, data.fund);
export const getFundAPI = (fundId: number): Promise<Account> =>
  apiHttpClient.get(`funds/${fundId}`);
